<template>
  <div class="h-100_">
    <content-header title="学校管理">
      <div class="school">
        <el-button @click="importVisible = true">导入</el-button>
        <el-button @click="exportData(true)">全部导出</el-button>

        <el-button
          @click="exportData(false)"
          :disabled="!(selection && selection.length)"
          >导出</el-button
        >

        <el-button type="primary" @click="addSchool">添加学校</el-button>
      </div>
    </content-header>
    <content-table
      ref="table"
      :total="total"
      :columns="columns"
      :setting="setting"
      :getTableData="getTableDataList"
      :tableList="tableList"
      @delete="deleteItem"
      @edit-outline="edit"
      @view="view"
      needSelection
      @selectionChange="selectionChange"
    ></content-table>
    <school-drawer
      title="添加学校"
      :visible.sync="addVisible"
      @submit="submit"
    ></school-drawer>
    <school-drawer
      title="编辑学校"
      :visible.sync="editVisible"
      :data="editData"
      @submit="editSure"
    ></school-drawer>
    <error-excel
      ref="err"
      @reImport="importVisible = true"
      @exportErrMessage="exportErrMessage"
      :errCloumn="columns.filter((it) => it.prop != 'createTime')"
      :batAddFunction="batAddWhite"
      :getTableData="getTableData"
      :listTransform="listTransform"
    ></error-excel>
    <el-dialog
      custom-class="room-header-add-a"
      title="批量导入"
      :visible.sync="importVisible"
      width="360px"
      style="padding: 0"
    >
      <el-alert
        v-if="filesName"
        :title="filesName"
        type="success"
        effect="dark"
        @close="filesName = ''"
      >
      </el-alert>
      <div v-else class="d-f ai-c" style="flex-direction: column">
        <el-button @click="downloadTemplate" type="text" style="width: auto"
          >点击下载学校信息管理模板</el-button
        >

        <el-button style="margin: 5px auto" @click="$refs.file.click()"
          ><img style="width: 10px" src="@/assets/upload.png" alt="" />
          上传文件</el-button
        >
        <input
          @change="fileChange"
          ref="file"
          type="file"
          style="display: block; width: 1px; height: 0; opacity: 0"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />

        <div slot="tip" class="f-12 c-9 ta-c">支持扩展名：xlsx</div>
      </div>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import ContentTable from "@/components/contentTable.vue";
import schoolDrawer from "./drawer.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("school");
import md5 from "../../views/common/md5";
import { getPassWord } from "../common/store";
import ErrorExcel from "../white/errorExcel.vue";
export default {
  name: "school",
  components: {
    ContentHeader,
    ContentTable,
    schoolDrawer,
    ErrorExcel,
  },
  data() {
    return {
      filesName:false,
      importVisible: false,
      list: ["", "月卡", "年卡", "永久卡", "免费"],
      priceList: ["", "9", "20", "99", ""],
      columns: [
        { label: "学校名称", prop: "universityName", width: "120" },
        {
          label: "学校地址（省）",
          prop: "addressProvince",
          width: "120",
        },
        {
          label: "学校地址（市）",
          prop: "addressCity",
          width: "120",
        },
        // { label: "管理员手机号", prop: "phone", width: "120" },
        { label: "管理员身份证号码", prop: "identityNo", width: "180" },
        // { label: "管理员密码", prop: "password" },

        {
          label: "会员费",
          prop: "vipType",
          width: "250",
          list: [],
          func: (it, row) => {
            const item = it.split("-");
            return item.length >= 3
              ? `${this.list[item[0]]}￥${+item[1] || item[2]}`
              : "";
          },
        },
        {
          label: "非会员费",
          prop: "vipType",
          width: "100",
          list: [],
          func: (it, row) => {
            const item = it.split("-");
            return item.length == 2 ? "￥" + item[1] : "";
          },
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: "200",
          date: true,
        },
        { label: "门锁ID区段", prop: "lockRange" },
        { label: "备注", prop: "remarks", width: "180" },
      ],
      setting: ["edit-outline", "delete", "view"],
      addVisible: false,
      editVisible: false,
      editData: null,
      selection: [],
    };
  },
  methods: {
    ...mapActions([
      "getTableData",
      "addTableData",
      "modifyTableData",
      "deleteTableData",
      "mmlogin",
      "getMMmessage",
      "getExcel",
      "getExcelTemplate",
      "uploadExcelTemplate",
    ]),
    fileChange(e) {
      const file = e.target.files[0];
      this.filesName = file.name;
      this.File = file;
    },
    view(val) {
      this.mmlogin().then((res) => {
        const { id, orgId, universityName } = val;
        window.open(
          `${
            this.mmPath
              ? this.mmPath
              : location.origin.split(":").slice(0, 2).join(":")
          }/mm?universityId=${id}&universityName=${universityName}&mmToken=${res}&orgId=${orgId}`,
          "_blank"
        );
      });
    },
    getTableDataList(val) {
      this.getTableData(val);
    },
    submit(val) {
      this.addTableData(val);
    },
    selectionChange(val) {
      console.log(val);
      this.selection = val;
    },
    downloadTemplate() {
      this.getExcelTemplate();
    },
    exportData(all = false) {
      this.getExcel({
        exportIds: all ? 0 : this.selection.map((it) => it.id).join(","),
      });
    },
    sure() {
      const fd = new FormData();
      fd.append("file", this.File);
      this.uploadExcelTemplate(fd).catch((err) => {
        console.log(err);
        this.$refs.err.getError(err);
      });
      this.importVisible = false;
    },
    exportErrMessage(uuid) {
      this.getErrWhiteExcel({ uuid });
    },
    confirmFunc() {
      return new Promise((res) => {
        this.$prompt("请输入登录密码进行验证！", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "password",
        }).then(({ value }) => {
          if (md5(value) == getPassWord()) {
            res();
          } else {
            this.$message.error("密码错误！");
          }
        });
      });
    },
    deleteItem(val) {
      //  删除改行
      this.confirmFunc().then(() => {
        this.$confirm(
          "确定要删除【" + val.universityName + "】这条数据吗？",
          "删除提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.deleteTableData(val.id);
        });
      });
    },
    addSchool() {
      // this.confirmFunc().then(() => {
      this.addVisible = true;
      // });
    },
    edit(val) {
      //  编辑该行
      // this.confirmFunc().then(() => {
      this.editData = { ...val };
      this.editVisible = true;
      // });
    },
    editSure(val) {
      this.modifyTableData(val);
    },
  },
  mounted() {
    this.$refs.table.getDataInit();
    this.getMMmessage();
  },
  computed: {
    ...mapState(["total", "tableList", "mmPath"]),
  },
  watch: {
    importVisible() {
      this.filesName = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.school {
  button {
    min-width: 76px;
  }
}
</style>