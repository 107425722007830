<template>
  <el-drawer :wrapperClosable="false" custom-class="room-add-room" :title="title || '编辑'" :visible="drawer"
    :before-close="close" :direction="'rtl'" :size="350">
    <div class="add-room-body">
      <div class="add-room-title require">学校名称</div>
      <el-input v-model="schoolParams.universityName" placeholder="请填写学校名称" show-word-limit />
      <div class="add-room-title require">学校编号</div>
      <el-input :value="schoolParams.universityCode" placeholder="请输入学校编号"
        @input="(val) => (schoolParams.universityCode = val.slice(0, 4))" />
      <div class="add-room-title require">学校地址</div>
      <div class="d-f">
        <el-input class="f-1" v-model="schoolParams.addressProvince" placeholder="省(市)" />
        <el-input style="margin-left: 3px" class="f-1" v-model="schoolParams.addressCity" placeholder="市(区)" />
      </div>

      <!-- <div class="add-room-title require">管理员手机号码</div>
      <el-input v-model="schoolParams.phone" placeholder="请填写手机号码" /> -->
      <!-- <div class="add-room-title require">管理员身份证号后6位</div>
      <el-input v-model="schoolParams.identityNo" placeholder="请填写身份证号码" /> -->
      <div class="add-room-title d-f ai-c jc-sb">
        重置密码(123456)
        <el-button :disabled="!isModify" @click="resetPass" size="mini" type="primary" style="float: right">重置</el-button>
      </div>

      <div class="add-room-title require">门锁ID</div>
      <div class="d-f ai-c">
        <el-input class="f-1" disabled v-model="schoolParams.fixedPrefix" placeholder="请输入门锁ID固定前缀"
          style="margin-right: 3px" />
        <el-input class="f-1" v-model="schoolParams.varyRange[0]" placeholder="00000" />
        <span style="margin: 0 3px">-</span>
        <el-input class="f-1" v-model="schoolParams.varyRange[1]" placeholder="99999" />
      </div>

      <div class="add-room-title ">合作运营商</div>
      <el-input class="f-1" v-model="schoolParams.cooperativeOperator" placeholder="请输入合作运营商" />
      <div class="add-room-title ">链接地址</div>
      <el-input class="f-1" v-model="schoolParams.websiteLink" placeholder="请输入链接地址" />
      <div class="add-room-title require">会员费设置</div>
      <div class="d-f ai-c jc-sb" style="margin-bottom: 3px">
        <span style="flex-basis:60px">月&emsp;卡:</span>
        <el-input size="mini" style="width: 100px" type="number" class="w-50_" v-model="vipMonth"></el-input>
        <span style="flex-basis:100px">原价：￥9</span>
      </div>
      <div class="d-f ai-c jc-sb" style="margin-bottom: 3px">
        <span style="flex-basis:60px">年&emsp;卡：</span>
        <el-input size="mini" style="width: 100px" type="number" class="w-50_" v-model="vipYear"></el-input>
        <span style="flex-basis:100px">原价：￥39</span>
      </div>
      <div class="d-f ai-c jc-sb">
        <span style="flex-basis:60px">永久卡：</span>
        <el-input size="mini" style="width: 100px" type="number" class="w-50_" v-model="vipAll"></el-input>
        <span style="flex-basis:100px">原价：￥139</span>
      </div>
      <div class="add-room-title ">非会员费设置</div>
      <el-input size="mini" style="margin-top: 10px" v-model="nonVipCount" type="number" placeholder="请输入折扣金额" />
      <div class="add-room-title">备注</div>
      <el-input size="mini" style="margin-top: 10px" v-model="schoolParams.remarks" placeholder="请输入备注" />
      <div class="add-room-title d-f jc-sb ai-c">
        <span>开锁广告</span>
        <el-switch v-model="schoolParams.adFlag"></el-switch>
      </div>
    </div>
    <div class="add-room-submit w-100_ d-f ai-c">
      <el-button type="primary" @click="addRoomFunc">保 存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("school");
export default {
  name: "school-params-change",
  data() {
    return {
      vipSet: "vip",
      drawer: false, //  抽屉
      schoolParams: {
        universityName: "",
        universityCode: "",
        addressProvince: "",
        addressCity: "",
        // phone: "",
        // password: "",
        lockRange: "",
        vipType: [],
        // vipDiscount: "",
        fixedPrefix: "BCA",
        varyRange: ["00000", "99999"],
        // identityNo: "",
        remarks: "",
        cooperativeOperator: "",
        adFlag: true,
        websiteLink: "", // 联系人链接或email地址，不做处理或提交给邮箱机态检查。 如"
      },
      loading: false,
      vipTypeList: [
        { id: 0, checked: true, label: "月费", money: 9 },
        { id: 1, checked: true, label: "年费", money: 39 },
        { id: 2, checked: true, label: "永久", money: 139 },
        // { id: 3, label: "免费" },
      ],
      isModify: false, //  是否为修改
      nonVipCount: 0,
      vipMonth: 9,
      vipYear: 39,
      vipAll: 139,
    };
  },
  methods: {
    ...mapActions(["changeSchoolMessage"]),
    resetPass() {
      this.$confirm('确定重置' + this.schoolParams.universityName + '的密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,

      }).then(() => {

        this.changeSchoolMessage({
          userId: this.schoolParams.userId, id: this.schoolParams.id, password: '123456'
        }).then(() => this.$message.success('重置成功')).catch(() => this.$message.error('重置失败'))

      })
    },
    close() {
      this.$emit("update:visible", false);
    },
    addRoomFunc() {
      const warning = {
        universityName: "请输入学校名称",
        universityCode: "请输入学校编码",
        // phone: "请输入管理员手机号码",
        fixedPrefix: "请输入门锁固定前缀",
        varyRange: "请输入门锁ID变化范围",
        addressProvince: "请输入学校地址",
        addressCity: "请输入学校地址",
        // identityNo: "请输入身份证号码"
        // cooperativeOperator: "请输入合作运营商",
      };
      // if (!this.isModify) {
      //   warning.password = "请输入管理员密码";
      // }
      const list = Object.keys(warning);
      let canIgo = true;
      for (let i = 0; i < list.length; i++) {
        if (Array.isArray(this.schoolParams[list[i]])) {
          if (this.schoolParams["varyRange"].filter(Boolean).length < 2) {
            this.$message.error(warning[list[i]]);
            canIgo = false;
            break;
          }
        } else if (
          !String(this.schoolParams[list[i]]) ||
          this.schoolParams[list[i]] == null
        ) {
          this.$message.error(warning[list[i]]);
          canIgo = false;
          break;
        }
      }
      console.log(canIgo)
      if (this.schoolParams.universityCode.length != 4) {
        this.$message.error("学校编号固定为4位");
        return;
      }
      if (this.schoolParams.varyRange.find(it => it.length != 5)) {
        this.$message.error("门锁ID编号固定为5位");
        return;
      }

      // if (
      //   !/^[0-9]{5}(\d|X|x)$/.test(
      //     this.schoolParams.identityNo
      //   )
      // ) {
      //   this.$message.error("请输入身份证号后6位");
      //   return;
      // }
      // if (
      //   !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
      //     this.schoolParams.phone
      //   )
      // ) {
      //   this.$message.error("请输入正确的手机号");
      //   return;
      // }
      if (canIgo) {
        const list = [
          this.vipYear,
          this.vipMonth,
          this.vipAll,
          // this.nonVipCount,
        ].map(Number);
        console.log(list)
        if (list.filter(Boolean).length < 3) {
          for (let i = 0; i < list.length; i++) {
            if (list[i] <= 0) {
              this.$message.error(
                "请输入" + ["年卡", "月卡", "永久卡", "非会员"][i] + "金额"
              );
              break;
            }
          }
          return;
        }
        this.close();
        this.$emit("submit", {
          ...this.schoolParams,
          adFlag: this.schoolParams.adFlag ? "1" : "2",
          varyRange: this.schoolParams.varyRange.join("-"),
          vipType: [
            // "0-" + (this.vipMonth ? 1 : 0) + "-9-" + this.vipMonth,
            // "1-" + (this.vipYear ? 1 : 0) + "-20-" + this.vipYear,
            // "2-" + (this.vipAll ? 1 : 0) + "-99-" + this.vipAll,
            // "3-" + this.nonVipCount,
            "1-" + this.vipMonth + "-9",
            "2-" + this.vipYear + "-39",
            "3-" + this.vipAll + "-139",
            "4-" + this.nonVipCount,
          ].join(":"),
        });
      }
    },
  },
  watch: {
    vipSet(val) {
      if (val == "notVip") {
        this.nonVipCount = this.nonVipCount || 0;
      }
    },
    "schoolParams.vipType"(val) {
      console.log(val);
    },
    vipTypeList: {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
    },
    drawer() {
      this.schoolParams = {
        universityName: "",
        // phone: "",
        addressProvince: "",
        addressCity: "",
        lockRange: "",
        // password: "",
        vipType: 1,
        // vipDiscount: "",
        fixedPrefix: "BCA",
        varyRange: ["00000", "99999"],
        universityCode: "",
        cooperativeOperator: "中国移动",
        adFlag: false,
        websiteLink: ""
      };
      this.nonVipCount = 0;
      this.vipMonth = 9;
      this.vipYear = 39;
      this.vipAll = 139;
      this.vipTypeList = [
        { id: 1, checked: true, label: "年费", money: 39 },
        { id: 2, checked: true, label: "永久", money: 139 },
        // { id: 3, label: "免费" },
      ];
      this.isModify = false;
    },
    visible(val) {
      this.drawer = val;
    },
    data(val) {
      const getArr = (arr) => {
        const a = [["", ""], [...arr, ""], arr, arr.slice(0, 2)];
        return a[arr.length];
      };
      if (val) {
        this.schoolParams = val;
        this.schoolParams.varyRange = getArr(
          this.schoolParams.varyRange.split("-")
        );
        this.schoolParams.length == 0 ? ["", ""] : console.log(val);
        this.schoolParams.adFlag = this.schoolParams.adFlag == "1";
        const d = val.vipType.split(":");
        this.vipMonth = d[0]?.split("-")?.slice(-2)[0] || 0;
        this.vipYear = d[1]?.split("-")?.slice(-2)[0] || 0;
        this.vipAll = d[2]?.split("-")?.slice(-2)[0] || 0;
        this.nonVipCount = d[3]?.split("-")?.slice(-1)[0] || 0;
        console.log(this.vipTypeList);
        this.isModify = true;
      }
    },
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.room-add-room {
  .add-room-body {
    padding: 0 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: calc(100% - 56px);
    overflow: auto;

    .add-room-title {
      line-height: 50px;
      color: #262626;
      font-weight: bold;
    }
  }

  .add-room-submit {
    bottom: 0;
    height: 56px;
    background: #fbfbfb;
    padding: 0 24px;
  }

  /deep/ .el-radio-button__orig-radio {
    &:checked+.el-radio-button__inner {
      color: #fa6400;
      background: #fff;
    }
  }
}
</style>
<style >
.room-add-room .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fa6400;
  background: #fff;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
</style>